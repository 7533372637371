import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CarouselView = makeShortcode("CarouselView");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`La maison offre un jardin arboré avec piscine et comprend deux étages organisés en demi niveaux.`}</p>
    <ul>
      <li parentName="ul">{`Au niveau bas du rdc se trouvent la salle à manger, la cuisine, son petit cellier et l’entrée avec son placard.`}</li>
      <li parentName="ul">{`Au niveau haut du rdc, le salon, une chambre avec salle d’eau, ainsi que des toilettes, et un grand placard.`}</li>
      <li parentName="ul">{`Au niveau bas de l’étage, la suite parentale, avec sa salle de bains, ses toilettes, et son solarium.`}</li>
      <li parentName="ul">{`Au niveau haut de l’étage, une grande chambre avec salle d’eau, toilettes, et un balcon donnant accès au jardin.`}</li>
    </ul>
    <h2>{`Piscine`}</h2>
    <ul>
      <li parentName="ul">{`De la piscine, en nageant vous profiterez d’une vue exceptionnelle sur la
vallée de la Siagne et sur le massif du Tanneron.`}</li>
      <li parentName="ul">{`Au loin vous apercevrez la mer.`}</li>
      <li parentName="ul">{`Entièrement clôturée la piscine est inaccessible aux petits enfants.`}</li>
      <li parentName="ul">{`Le bassin de 7,6 m sur 3,8 m dont la profondeur varie de 1.10 m a 1.85 m,
intègre un escalier et une banquette.`}</li>
    </ul>
    <CarouselView images={['piscine_01.jpg', 'piscine_03.jpg', 'piscine_04.jpg', 'piscine_nuit.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Pool House`}</h2>
    <p>{`Le pool house de 9 m² inclus un espace de rangement et le bloc
technique de traitement de l’eau par chlorinateur au sel et régulateur de PH.`}</p>
    <CarouselView images={['piscine_02.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Séjour`}</h2>
    <p>{`L’entrée de la maison donne sur le séjour de 21 m² qui a une double exposition
sud et ouest.
Une baie vitrée, orientée sud, offre une belle vue sur la mer,
et les collines du Tanneron. Une seconde baie vitrée à galandage,
orientée ouest, donne sur le jardin et la piscine ainsi
que sur une terrasse couverte sur laquelle une salle a manger
d’été trouvera naturellement sa place.`}</p>
    <CarouselView images={['sam_01.jpg', 'sam_02.jpg', 'sam_03.jpg', 'sam_04.jpg', 'sam_05.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Salon`}</h2>
    <p>{`Le salon de 24 m² est orienté ouest. Sa baie vitrée à galandage donne accès
à une petite terrasse sur le jardin et la piscine.`}</p>
    <CarouselView images={['salon_01.jpg', 'salon_02.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Cuisine`}</h2>
    <p>{`La cuisine indépendante de 16 m² est séparée de la salle à manger par une double porte
vitrée a galandage. Elle possède un petit cellier et est complètement équipée.
four à chaleur tournante, plaque gaz 5 feux, double évier,lave-vaisselle 12 couverts.`}</p>
    <CarouselView images={['cuisine_02.jpg', 'cuisine_03.jpg', 'cuisine_04.jpg', 'cuisine_05.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Suite parentale`}</h2>
    <ul>
      <li parentName="ul">{`Grande chambre de 17,4 m2, avec une grande penderie.`}</li>
      <li parentName="ul">{`Salle de bains privative avec deux vasques, une baignoire, une douche, un bidet, WC.`}</li>
      <li parentName="ul">{`Une baie vitrée à galandage donne sur une grand solarium privatif de 24m²`}</li>
      <li parentName="ul">{`Double exposition sud et ouest, vue sur la mer et les collines.`}</li>
    </ul>
    <CarouselView images={['chambre_master_01.jpg', 'chambre_master_02.jpg', 'chambre_master_terrasse.jpg', 'chambre_master_sdb01.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Chambre du haut`}</h2>
    <ul>
      <li parentName="ul">{`Chambre de 12,4 m² , orientée Ouest, avec une penderie.`}</li>
      <li parentName="ul">{`Salle d’eau privative avec lavabo et douche, et toilettes séparés.`}</li>
      <li parentName="ul">{`Double porte fenêtre et balcon donnant accès au jardin.`}</li>
    </ul>
    <CarouselView images={['chambre_02_01.jpg', 'chambre_02_02.jpg', 'chambre_02_balcon.jpg', 'chambre_02_sdb01.jpg', 'chambre_02_sdb02.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Chambre du bas`}</h2>
    <ul>
      <li parentName="ul">{`Chambre de 12m² ,orientée est, avec fenêtre sur le jardin.`}</li>
      <li parentName="ul">{`Salle d’eau privée avec une vasque et une grande douche.`}</li>
      <li parentName="ul">{`Toilettes à côté de la chambre.`}</li>
    </ul>
    <CarouselView images={['chambre_bas_01.jpg', 'chambre_bas_02.jpg', 'chambre_bas_sdb01.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Studio`}</h2>
    <ul>
      <li parentName="ul">{`Studio de 21 m² , orienté sud, avec salle d’eau une vasque, douche, WC.
Penderie.`}</li>
      <li parentName="ul">{`Cuisine équipée, four, plaque a induction, lave vaisselle 12 couverts, évier inox.`}</li>
      <li parentName="ul">{`Quadruple baie vitrée ouvrant sur une grande terrasse,`}</li>
      <li parentName="ul">{`Accès arrière au jardin et une petite terrasse.`}</li>
    </ul>
    <CarouselView images={['exterieur_studio.jpg', 'studio.jpg', 'studio_02.jpg', 'studio_03.jpg', 'studio_04.jpg', 'studio_05.jpg', 'studio_06.jpg', 'studio_terrasse.jpg']} thumb={false} mdxType="CarouselView" />
    <hr style={{
      borderTop: '1px solid #505050'
    }} />
    <h2>{`Garage et Atelier`}</h2>
    <ul>
      <li parentName="ul">{`Le garage double de 52 m² donne accès à un atelier ( aveugle) de 23 m².`}</li>
    </ul>
    <CarouselView images={['exterieur_garage.jpg', 'exterieur_garage_02.jpg']} thumb={false} mdxType="CarouselView" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      